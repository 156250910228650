<template>
  <img class="subtitle-image" :src="imageUrl" />
</template>

<script>
import { resolveCmsMediaUrl } from "/utils/helpers";

export default {
  props: {
    image: {
      required: true,
    },
  },
  data() {
    return {
      imageUrl: resolveCmsMediaUrl(this.image.data.attributes.url),
    };
  },
};
</script>

<style lang="scss" scoped>
.subtitle-image {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
